import React from "react";
import "./secondSec.css";
import service1 from "../../media/images/service1.png";
import service2 from "../../media/images/service2.png";
import service3 from "../../media/images/service3.png";
import service4 from "../../media/images/service4.png";
import service5 from "../../media/images/service5.png";
import service6 from "../../media/images/service6.png";
import service7 from "../../media/images/service7.png";
import service8 from "../../media/images/service8.png";
import service9 from "../../media/images/service9.png";
import service10 from "../../media/images/service10.png";

const SecondSec = () => {
    const services = [
        {
            head: "ייעוץ רוחני אישי",
            contact:
                "תקשורים ישירים מהמדריכים האישיים ומהעולם שמעבר, שמסייעים לך למצוא תשובות ולספק הכוונה ברורה לגבי הדרך שלך.",
            img: service1,
        },
        {
            head: "ריפוי רוחני ופיזי דרך אנרגיית המלאכים",
            contact:
                "ריפוי שנעשה באמצעות עבודה אנרגטית, כדי להשיב איזון ושיפור ברבדים השונים של הגוף והנפש.",
            img: service2,
        },
        {
            head: "זיהוי בעיות רפואיות",
            contact:
                "יכולת לזהות בעיות רפואיות ולהבין את מקורן, כך שתוכל לקבל כיווני פעולה או טיפולים מתאימים.",
            img: service3,
        },
        {
            head: "תיקשור עם נפטרים – על פי ההלכה היהודית",
            contact:
                "אני מאפשרת חיבור לעולם שמעבר ותקשורת עם נפטרים על פי עקרונות היהדות, לקבלת מסרים והכוונה. תהליך זה מסייע בסגירת מעגלים ומאפשר להתמודד עם כאב ואובדן.",
            img: service4,
        },
        {
            head: "תיקשור בעזרת תמונה",
            contact:
                "תקשורת עם תינוקות, ילדים אוטיסטים ואנשים עם מוגבלויות ללא יכולת דיבור - תיקשור דרך תמונה שמאפשר לקבל מסרים רוחניים ולתקשר עם תינוקות, ילדים אוטיסטים או אנשים בעלי מוגבלויות שונות שאין להם אפשרות לדבר ולהביע רגשות.",
            img: service5,
        },
        {
            head: "פתיחה בספר תהילים",
            contact: "שאלת שאלה וקבלת תשובה מדוייקת בעזרת ספר התהילים.",
            img: service6,
        },
        {
            head: "ניקוי אנרגטי בנרות",
            contact: "להוצאת אנרגיות שליליות והחזרת איזון פנימי.",
            img: service7,
        },
        {
            head: "ניתוק אנרגטי בנרות",
            contact:
                "ניתוק אנרגטי מקשרים או אנרגיות שליליות שמונעות ממך להתרומם ולהמשיך את חייך ללא הרגשת מועקה ותקיעות.",
            img: service8,
        },
        {
            head: "שרות עד הבית – טיהור בתים",
            contact:
                "טיהור אנרגטי של הבית או מקום העבודה, לצורך חידוש האנרגיות, הוצאת אנרגיות שליליות והחזרת איזון אנרגטי ושיפור האוירה במקום.",
            img: service9,
        },
        {
            head: "ערבי תיקשור חווייתיים",
            contact:
                "אני עורכת ערבי תיקשור חווייתיים, עד 10 משתתפים, עבור כל אירוע – ימי הולדת, מסיבת רווקות וסתם מפגש כייפי עם החברים. הערבים מותאמים אישית, ומספקים חוויה רוחנית מעשירה, תוך קבלת מסרים והכוונה, עם חיבור מהעולמות שמעבר",
            img: service10,
        },
    ];

    return (
        <section id="services">
            <h1>שירותי תקשור</h1>
            <div className="services">
                {services &&
                    services.map((service, index) => (
                        <div key={index}>
                            <img src={service.img} alt={service.head} />
                            <h2>{service.head}</h2>
                            <p>{service.contact}</p>
                        </div>
                    ))}
            </div>
            <a href="#contact" id="contactBtn" aria-label="Contact">
                צור קשר <i className="bi bi-chevron-left"></i>
            </a>
        </section>
    );
};

export default SecondSec;
