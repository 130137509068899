import React from "react";
import "./footer.css";
import logo from "../../media/images/logo.png";
const Footer = () => {
    return (
        <footer>
            <div>
                <div>
                    <h1>אסנת דיין | תקשור</h1>
                    <hr />
                    <h2>כל הזכויות שמורות&copy;</h2>
                </div>
                <img src={logo} alt="Logo" />
            </div>
            <div>
                <a href="https://www.daniel-allali.com/">
                    האתר עוצב ונבנה על ידי דניאל אלאלי
                </a>
            </div>
        </footer>
    );
};

export default Footer;
