import React from "react";
import "./recommendations.css";

const Recommendations = () => {
    return (
        <section id="recommendations">
            <h1 className="mainHead">המלצות</h1>
            <i className="bi bi-heart"></i>
            <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
            >
                <div className="carousel-indicators">
                    <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                    ></button>
                    <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                    ></button>
                    <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                    ></button>
                </div>
                <div className="carousel-inner">
                    <div
                        className="carousel-item active"
                        data-bs-interval="10000"
                    >
                        <div className="carousel-caption">
                            <h2>מיקי</h2>
                            <p>
                                ברצוני להמליץ בחום על אוסנת, מתקשרת רוחנית בעלת
                                כישרון יוצא דופן. פניתי לאוסנת בתקופה של בלבול
                                ואי-ודאות, וחוויתי איתה תהליך משמעותי ומרפא.
                                אוסנת ניחנה ברגישות וביכולת הקשבה יוצאת מן הכלל,
                                מה שהפך כל מפגש איתה לחוויה עמוקה ומרגשת. בזכות
                                החיבור המיוחד שלה לעולם הרוחני וההכוונה המדויקת
                                שקיבלתי ממנה, הצלחתי לראות את הדברים בפרספקטיבה
                                חדשה ולהתקדם בחיי עם בהירות וביטחון.
                            </p>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="carousel-caption">
                            <h2>מיקי2</h2>
                            <p>
                                ברצוני להמליץ בחום על אוסנת, מתקשרת רוחנית בעלת
                                כישרון יוצא דופן. פניתי לאוסנת בתקופה של בלבול
                                ואי-ודאות, וחוויתי איתה תהליך משמעותי ומרפא.
                                אוסנת ניחנה ברגישות וביכולת הקשבה יוצאת מן הכלל,
                                מה שהפך כל מפגש איתה לחוויה עמוקה ומרגשת. בזכות
                                החיבור המיוחד שלה לעולם הרוחני וההכוונה המדויקת
                                שקיבלתי ממנה, הצלחתי לראות את הדברים בפרספקטיבה
                                חדשה ולהתקדם בחיי עם בהירות וביטחון.
                            </p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="carousel-caption">
                            <h2>מיקי3</h2>
                            <p>
                                ברצוני להמליץ בחום על אוסנת, מתקשרת רוחנית בעלת
                                כישרון יוצא דופן. פניתי לאוסנת בתקופה של בלבול
                                ואי-ודאות, וחוויתי איתה תהליך משמעותי ומרפא.
                                אוסנת ניחנה ברגישות וביכולת הקשבה יוצאת מן הכלל,
                                מה שהפך כל מפגש איתה לחוויה עמוקה ומרגשת. בזכות
                                החיבור המיוחד שלה לעולם הרוחני וההכוונה המדויקת
                                שקיבלתי ממנה, הצלחתי לראות את הדברים בפרספקטיבה
                                חדשה ולהתקדם בחיי עם בהירות וביטחון.
                            </p>
                        </div>
                    </div>
                </div>
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="prev"
                >
                    <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="next"
                >
                    <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    );
};

export default Recommendations;
