import React from "react";
import "./contact.css";

const Contact = () => {
    return (
        <div id="contact">
            <h1 className="mainHead">צור קשר</h1>
            <div>
                <div>
                    <ul>
                        <li>
                            <i className="bi bi-telephone-fill"></i>
                            <h2>050-6666341</h2>
                        </li>
                        <li>
                            <i className="bi bi-geo-alt-fill"></i>
                            <h2>
                                ניתן לתאם פגישה אישית/יעוץ טלפוני/פגישה בזום
                            </h2>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Contact;
