import React from "react";
import "./openSec.css";

const OpenSec = () => {
    return (
        <section id="openSec">
            <div></div>
            <div>
                <h1>אוסנת דיין</h1>
                <p>
                    ריפוי ייעוץ והדרכה רוחנית לחיבור עמוק ולשלווה אמיתית יעוץ
                    אישי שיתן לך למצוא את התשובות לשאלות שלך ולקבל מסרים מהעולם
                    שמעבר. אני מציעה ייעוץ אישי והכוונה רוחנית, כולל תקשורים
                    ישירים מהעולם שמעבר, שמסייעים לך למצוא תשובות לשאלותיך ולקבל
                    הכוונה ברורה להמשך הדרך. כל מפגש מותאם אישית לצרכים שלך,
                    במטרה להביא בהירות ולסייע בשינויים חיוביים בחיים. במידת
                    הצורך, אני גם מבצעת ריפוי רוחני ופיזי דרך אנרגיית המלאכים,
                    ומסוגלת לזהות בעיות רפואיות ולסייע בהבנתן.
                </p>
            </div>
        </section>
    );
};

export default OpenSec;
