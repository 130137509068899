import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import SmallScreenHeader from "./SmallScreenHeader";
import logo from "../../media/images/logo.png";

const Header = () => {
    const [displayNav, setDisplayNav] = useState(false);
    const [visible, setVisible] = useState(true);
    const [prevY, setPrevY] = useState(window.scrollY);
    const header = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentY = window.scrollY;
            if (prevY > currentY && prevY - currentY > 5) {
                setVisible(true);
            } else if (currentY > prevY && currentY - prevY > 5) {
                setVisible(false);
            }
            setPrevY(currentY);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevY]);

    useEffect(() => {
        if (header.current) {
            header.current.style.transition = "transform 0.3s";
            header.current.style.transform = visible
                ? "translateY(0)"
                : "translateY(-220px)";
        }
    }, [visible]);

    return (
        <header ref={header} className="big_screen">
            <button
                onClick={() => {
                    setDisplayNav(true);
                }}
            >
                <i className="bi bi-list"></i>
            </button>
            <nav>
                <ul>
                    <li>
                        <a href="#contact">צור קשר</a>
                    </li>
                    |
                    <li>
                        <a href="#recommendations">המלצות</a>
                    </li>
                    |
                    <li>
                        <a href="#process">תהליך</a>
                    </li>
                    |
                    <li>
                        <a href="#about">אודות</a>
                    </li>
                    |
                    <li>
                        <a href="#services">שירותי תקשור</a>
                    </li>
                    |
                    <li>
                        <a href="#">בית</a>
                    </li>
                </ul>
            </nav>
            <img src={logo} alt="Logo" />
            {displayNav && (
                <SmallScreenHeader
                    setDisplayNav={setDisplayNav}
                    displayNav={displayNav}
                />
            )}
        </header>
    );
};

export default Header;
