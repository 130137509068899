import React from "react";
import "./about.css";

const About = () => {
    return (
        <section id="thirdSec">
            <div id="about">
                <div>
                    <div>
                        <h1 className="mainHead">אודות</h1>
                        <p>
                            אני מתקשרת רוחנית המתמחה בהכוונה, ייעוץ ותמיכה אישית
                            באמצעות חיבור לעולמות הרוח. בעזרת כלים אנרגטיים
                            ותקשורת עם מדריכים רוחניים, אני עוזרת ללקוחות למצוא
                            תשובות, לקבל בהירות ולחוות שלווה פנימית. מטרתי היא
                            ללוות אותך בדרך להגשמה עצמית ולצמיחה אישית. כל מפגש
                            נעשה באהבה ובמקצועיות.
                        </p>
                    </div>
                    <a href="#contact" id="contactBtn" aria-label="Contact">
                        צור קשר <i className="bi bi-chevron-left"></i>
                    </a>
                </div>
                <div className="osnatImage"></div>
            </div>
            <div id="process">
                <div className="processImage"></div>
                <div>
                    <div>
                        <h1 className="mainHead">תהליך</h1>
                        <p>
                            אני מתקשרת רוחנית המתמחה בהכוונה, ייעוץ ותמיכה אישית
                            באמצעות חיבור לעולמות הרוח. בעזרת כלים אנרגטיים
                            ותקשורת עם מדריכים רוחניים, אני עוזרת ללקוחות למצוא
                            תשובות, לקבל בהירות ולחוות שלווה פנימית. מטרתי היא
                            ללוות אותך בדרך להגשמה עצמית ולצמיחה אישית. כל מפגש
                            נעשה באהבה ובמקצועיות.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
